.metric-section {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  width: 100%;
}

@media (max-width: 768px) {
  .metric-section {
    max-width: 100%;
    min-width: 100%;
  }
}

.metric-card {
  width: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--accent-5);
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.metric-card.animate {
  opacity: 1;
  transform: translateY(0);
}

.metric-card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding: 1rem;
  border-radius: 8px;
  background-color: "#ffffff";
  width: 97%;
}

.metric-container {
  width: 100%;
  gap: 1rem;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
