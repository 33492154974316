.experience-card {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  transition: all 0.3s ease;
  max-width: 350px;
  min-width: 300px;
  height: 180px;
}

.experience-card-column {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 0.1rem;
}

.experience-card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}

.experience-card-header-title {
  gap: 1rem;
  display: flex;
  flex-direction: row;
}
