.app-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  overflow: hidden;
}

.app-icon img {
  display: block;
}

.app-icon.xsmall img {
  height: 30px;
}

.app-icon.small img {
  height: 40px;
}

.app-icon.medium img {
  height: 70px;
}

.app-icon.large img {
  height: 150px;
}
