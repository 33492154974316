.general-search-container {
  position: relative;
}

.search-results-container {
  position: absolute;
  top: calc(
    100% + 8px
  ); /* Add space between the text field and the results container */
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  visibility: hidden; /* Hide the container when not focused */
}

.search-results-container.show {
  opacity: 1;
  transform: translateY(0);
  visibility: visible; /* Show the container when focused */
}

.search-result-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.search-result-item:last-child {
  border-bottom: none;
}

.search-result-item h3 {
  margin: 0;
  font-size: 16px;
}

.search-result-item p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #666;
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem;
}

.empty-results-message {
  padding: 10px;
  text-align: center;
  font-size: 14px;
  color: #666;
}
