.sidebar-container {
  width: 17%;
  display: flex;
  height: 100vh;
}

.sidebar {
  gap: 1rem;
  width: 100%;
  height: 100vh;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
  background-color: background;
}
