.pagination-container {
  gap: 1rem;
  width: 100%;
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: flex-end;
  flex-grow: 1; /* Ensure the container takes up the full width */
}

.members-table-heading-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.members-table-title-row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.exception-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
}

.exception-card {
  gap: 1rem;
  padding: 2rem;
  text-align: center;
}

.exception-card-content {
  gap: 1rem;
  display: flex;
  flex-direction: row;
}

.blurred {
  filter: blur(5px);
}

.row-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.row-column {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.tags-container {
  gap: 0.3rem;
  display: flex;
  flex-direction: row;
}

.contact-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.table-clickable-row {
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
}

.table-clickable-row:hover {
  background-color: var(--gray-2);
}
