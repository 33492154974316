.header-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.header-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}

.tags-container {
  gap: 0.3rem;
  display: flex;
  flex-direction: row;
}

.contact-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.info-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.name-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 0.5rem;
}
