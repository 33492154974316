.resources-container {
  display: flex;
  direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1500px;
  margin-top: 5rem;
}

@media (max-width: 768px) {
  .resources-container {
    max-width: 100%;
    min-width: 100%;
  }
}

.resources-title {
  gap: 1rem;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 5rem;
}

.resources-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}
