.search-result-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  flex-direction: row;
}

.search-result-item:hover {
  background-color: #f5f5f5;
}

.search-result-item-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.search-result-item-icon {
  width: 40px;
  height: 40px;
  border-radius: 20%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}
