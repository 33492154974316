.resource-card-container {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  justify-content: start;
  align-items: start;
  max-height: 500px;
  overflow-y: auto;
  width: 350px;
  gap: 1rem;
}

.resource-card-title {
  width: 100%;
  text-align: center;
}

.resource-card-description {
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
}

.resource-card-content {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
}

.resource-card-icon {
  color: var(--accent-9);
}
