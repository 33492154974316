.get-in-touch-section {
  gap: 2rem;
  display: flex;
  direction: row;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  max-width: 1500px;
  margin-top: 1rem;
}

.get-in-touch-column {
  gap: 2rem;
  width: 30%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .get-in-touch-column {
    min-width: 100%; /* Allow the form to be smaller on mobile devices */
  }
}

.get-in-touch-card-form {
  gap: 1rem;
  display: flex;
  width: fit-content;
  flex-direction: column;
  min-width: 500px;
  max-width: 800px;
}

@media (max-width: 768px) {
  .get-in-touch-card-form {
    min-width: 100%; /* Allow the form to be smaller on mobile devices */
  }
}
