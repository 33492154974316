.feedback-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  margin: 0 auto;
  margin-top: 5rem;
  max-width: 1200px;
  gap: 2rem;
}

@media (max-width: 768px) {
  .feedback-container {
    max-width: 100%;
    min-width: 100%;
  }
}

.feedback-items-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin-top: 2rem;
}

.section-item {
  gap: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 10px;
  background-color: var(--color-white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  max-width: 500px;
  min-width: 400px;
}

.section-item-column {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 1rem;
}

.experience-icon-item {
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 2rem;
  padding: 0.5rem;
  border-radius: 5px;
  color: var(--accent-1);
  background-color: var(--accent-9);
}
