.row-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.row-column {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.tags-container {
  gap: 0.3rem;
  display: flex;
  flex-direction: row;
}

.contact-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.table-clickable-row {
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
}

.table-clickable-row:hover {
  background-color: var(--gray-2);
}

.rt-BaseDialogContent {
  position: fixed;
}

.dialog-content {
  position: fixed !important;
  top: 0;
  bottom: 0;
  right: 1rem;
  height: 98%;
  width: 30%;
  min-width: 400px; /* Adjust width as needed */
  background-color: white;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  margin: 1rem;
}

.dialog-content[data-state="open"] {
  transform: translateX(0);
}
