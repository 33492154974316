.section-container {
  display: flex;
  direction: row;
  justify-content: center;
  align-items: center;
  padding: 5%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-height: 500px;
  max-width: 1500px;
}

@media (max-width: 768px) {
  .section-container {
    max-width: 100%;
    min-width: 100%;
  }
}

.section-column {
  gap: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 50%;
  padding: 0 20px;
}

.section-column.text {
  padding-right: 10%;
}

.section-column.image {
  width: 50%;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.trustpilot-container {
  gap: 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.stars-list-container {
  height: 100%;
  gap: 0.2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.efficiency-container {
  gap: 1rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.section-row {
  gap: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  width: 100%;
  padding: 0 20px;
}

.section-row.trust {
  align-items: center;
  padding-left: 0;
}

.illustration-image {
  border-radius: 8px;
}
